import * as React from "react"
import * as styles from './css/repaymentVia.module.styl'
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout";
import { widthInfo } from "../util/index.js"

import Seo from "../components/seo"
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

let width = widthInfo()
const RepaymentViabyEAPage = () => {
  const PictureData = useStaticQuery(graphql`
    query  RepaymentViabyEAQuery {
       
        bannerPicture: file(relativePath: { eq: "paymentVia/bannerbg.png" }) {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        activeLinePicture: file(relativePath: { eq: "paymentVia/activeLine.png" }) {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0101Picture: file(relativePath: { eq: "paybyEA/0101.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0102Picture: file(relativePath: { eq: "paybyEA/0102.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0103Picture: file(relativePath: { eq: "paybyEA/0103.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0104Picture: file(relativePath: { eq: "paybyEA/0104.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0105Picture: file(relativePath: { eq: "paybyEA/0105.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0106Picture: file(relativePath: { eq: "paybyEA/0106.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
       goodPicture: file(relativePath: { eq: "paybyEA/good.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        wainPicture: file(relativePath: { eq: "paybyEA/wain.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0201Picture: file(relativePath: { eq: "paybyEA/0201.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0202Picture: file(relativePath: { eq: "paybyEA/0202.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0203Picture: file(relativePath: { eq: "paybyEA/0203.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea0204Picture: file(relativePath: { eq: "paybyEA/0204.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        eaIconPicture: file(relativePath: { eq: "paybyEA/ealogo.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea1_1Picture: file(relativePath: { eq: "paybyEA/ea1.1.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea1_2Picture: file(relativePath: { eq: "paybyEA/ea1.2.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea1_3Picture: file(relativePath: { eq: "paybyEA/ea1.3.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea1_4Picture: file(relativePath: { eq: "paybyEA/ea1.4.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea1_5Picture: file(relativePath: { eq: "paybyEA/ea1.5.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea05Picture: file(relativePath: { eq: "paybyEA/ea05.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ealogoH5Picture: file(relativePath: { eq: "paybyEA/ealogoH5.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        ea_0104Picture: file(relativePath: { eq: "paybyEA/ea0104.png" }) {
          childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            }
          }
        },
        
    }
  `)

  const {  bannerPicture, activeLinePicture, ea0101Picture, ea0102Picture, ea0103Picture, ea0104Picture, ea0105Picture, ea0106Picture  ,wainPicture ,ea0204Picture,ea0203Picture,ea0202Picture,ea0201Picture , ea1_5Picture, ea1_4Picture, ea1_3Picture, ea1_2Picture, ea1_1Picture, ea05Picture,ea_0104Picture} = PictureData;
  
  return (
    // logoPicture,
    // logoPicture: file(relativePath: { eq: "paymentVia/logo.png" }) {
    //   childImageSharp {
    // fluid {
    // ...GatsbyImageSharpFluid
    // }
    // }
    // },
    <Layout>
      <Seo title="RepaymentViabyEAPage"></Seo>
      <header className={styles.paybanner}>
        <div className={styles.paybannercenter}>
          <div className={styles.paylogo}></div>

          {/* <Img 
    fluid={logoPicture.childImageSharp.fluid}
    alt="A corgi smiling happily" /> */}
          <div className={styles.paybannerInfo}>
            {width > 1024 ?
              <div>
                <div className={styles.paybannerTitle}>
                  Barwaqt
                  <br />
                  Repayment Methods
                </div>
                <Img
                  className={styles.paybannerLogo}
                  fluid={bannerPicture.childImageSharp.fluid}
                  alt="A corgi smiling happily" />
              </div>
              :
              <Img
                className={styles.paybannerLogo}
                fluid={bannerPicture.childImageSharp.fluid}
                alt="A corgi smiling happily" />
            }

          </div >
        </div >
      </header >
      {width > 1024 ?
        // pc
        <div className={styles.paynav}>
          <div className={styles.payNav80}>
            <li className={styles.payli} >
              <div className={styles.payliInfo}>
                <span>  <Link to='/repaymentViabyWa'>Repayment Via Mobile Wallet</Link></span>

              </div>
            </li>
            <li className={styles.payli} >
              <div className={styles.payliRightLine}>

                <span className={styles.active}>    Repayment Via  Easypaisa Token</span>

                <Img
                  className={styles.paybottomLine}
                  fluid={activeLinePicture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />

              </div>

            </li>
          </div>
        </div>
        :
        // H5
        <div className={styles.paynav}>
          <div className={styles.payNav80}>
            <li className={`${styles.payli} `} >
              <div className={styles.payliInfo}>
                <span>  <Link to='/repaymentViabyWa'>Repayment Via Mobile Wallet</Link></span>
              </div>
            </li>
            <li className={`${styles.payli}  `} >
              <div className={styles.payliRightLine}>
                <span >    Repayment Via  Easypaisa Token</span>
              </div>
            </li>
          </div>
        </div>
      }
      <div className={styles.content}>

        {/*STEP里面的数据 */}
        <div className={styles.fourContent}>

          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null
            } 01</span>
            <span className={styles.contentTitleText}>Login To Your Barwaqt App by using your login details.</span>
          </div>
          <Img
            className={styles.pict481}
            fluid={ea0101Picture.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null
            } 02</span>
            <span className={styles.contentTitleText}>Go to the Repayment Methods and choose Repayment via Easypaisa Token.</span>
          </div>
          <Img
            className={styles.pict540}
            fluid={ea0102Picture.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null
            } 03</span>
            <span className={styles.contentTitleText}>Follow the Repayment Steps as shown in the image below.  </span>
          </div>
          <div className={styles.foursamllPart}>
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>Your token is your Unique Id which will be required in order to pay your loan amount.</span>
            </div>
            <Img
              className={styles.pict492}
              fluid={ea0103Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.fourItemTitle}>
              <span className={styles.fourItemIcon}></span>
              <span className={styles.fourItemText}>The Token generation SMS will also be received at your mobile phone against your order.</span>
            </div>
            <Img
              className={styles.pict492}
              fluid={ea0104Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
          <div className={styles.contentTitle}>
            <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null} 04</span>
            <span className={styles.contentTitleText}>There are two options to pay your amount using Token. </span>
          </div>
          <div className={styles.marginTop24} ></div> 
          <div className={styles.foursamllPart}>
            <div className={styles.secondTitle}>
              <span className={styles.secondTitleIcon}>01</span>
              <span className={styles.secondTitleText}>Repayment via Token using Easypaisa App</span>
            </div>
            {/* 01里面的 */}
            <div className={styles.fourthredPart}>
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>Sign in to your Easypaisa account by entering the 5-digit PIN.</span>
              </div>
              <Img
                className={styles.pict312}
                fluid={ea1_1Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>Tap on the “More” button on your Easypaisa App.</span>
              </div>
              <Img
                className={styles.pict422}
                fluid={ea1_2Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>Tap the “Online Payment” button on your App.</span>
              </div>
              <Img
                className={styles.pict330}
                fluid={ea1_3Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>Enter your Barwaqt Token Number, then tap on the NEXT button.</span>
              </div>
              <Img
                className={styles.pict312}
                fluid={ea_0104Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>Tap on the Pay Now button to pay your loan.</span>
              </div>
              <Img
                className={styles.pict498}
                fluid={ea1_4Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
              <div className={styles.fourItemTitle}>
                <span className={styles.fourItemIcon}></span>
                <span className={styles.fourItemText}>When payment has been made, Transaction Successful receipt will be received from Easypaisa. </span>
              </div>
              <Img
                className={styles.pict312}
                fluid={ea1_5Picture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
            </div>
          </div>

          <div className={styles.foursamllPart}>
            <div className={styles.secondTitle}>
              <span className={styles.secondTitleIcon}>02</span>
              <span className={styles.secondTitleText}>Repayment Via Easypaisa Agent.</span>
            </div>

            <div className={`${styles.fourcontentTitle} ${styles.h5EafourcontentTitle}`}>
              <span className={styles.fourIcon}>01</span>
              <span className={styles.fourIconText}>Give your loan amount to the Easypaisa Agent.</span>
            </div>
            <Img
              className={styles.pict416}
              fluid={ea0105Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={`${styles.fourcontentTitle} ${styles.h5EafourcontentTitle}`}>
              <span className={styles.fourIcon}>02</span>
              <span className={styles.fourIconText}>Loan Repayment Process at Easypaisa Agent’s Interface.</span>
            </div>
            <div className={styles.fourthredPart}>
              <div className={styles.tips}>
                When the customer goes to the Easypaisa Agent to repay the loan amount these are the following steps which your retailer will go through.
              </div>
              <div className={styles.wain}>
                <Img
                  className={styles.wainIcon}
                  fluid={wainPicture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <div className={styles.wainText}>These steps are just to give an idea about what steps retailer will follow to repay your loan amount. Customer does not have to do anything with it.</div>
              </div>

              <div className={styles.fourDoublePart}>
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>The Easypaisa agent will enter the Token Number which customer will provide.</span>
                </div>
                <Img
                  className={styles.pict498}
                  fluid={ea0201Picture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>The Easypaisa agent will enter the customer’s Mobile Number.</span>
                </div>
                <Img
                  className={styles.pict498}
                  fluid={ea0202Picture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>He will put his 5-digit PIN to make the payment successful against the loan amount.</span>
                </div>
                <Img
                  className={styles.pict498}
                  fluid={ea0203Picture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
                <div className={styles.fourItemTitle}>
                  <span className={styles.fourItemIcon}></span>
                  <span className={styles.fourItemText}>Congratulations! The payment has been paid against the loan.</span>
                </div>
                <Img
                  className={styles.pict498}
                  fluid={ea0204Picture.childImageSharp.fluid}
                  alt="A corgi smiling happily"
                />
              </div>
            </div>
          </div>
          {/* step3 */}
          <div className={styles.fourContent}>

            <div className={styles.contentTitle}>
              <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null
              } 05</span>
              <span className={styles.contentTitleText}>Congratulations! Your loan amount has been paid. You will receive Confirmation SMS on your mobile phone.</span>
            </div>
            <Img
              className={styles.pict481}
              fluid={ea0106Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.contentTitle}>
              <span className={styles.contentTitleIcon}>   {width > 1024 ? <span>STEP </span> : null
              } 06</span>
              <span className={styles.contentTitleText}>Finally, you can see your Order has been settled on Barwaqt App.</span>
            </div>
            <Img
              className={styles.pict312}
              fluid={ea05Picture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}


export default RepaymentViabyEAPage